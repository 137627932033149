import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PaginationButtons from "src/components/pagination";
import Scrollbar from "src/components/Scrollbar";
import useLocales from "src/hooks/useLocales";
import { BodyRow } from "src/pages/admin/reports/components";
import EmptyTable from "src/components/emptyTable";
import { Currency } from "src/components/with-prefix";

const DataList = ({ data, rowStart, ...rest }) => {
  const { translate } = useLocales();

  const isEmpty = !Boolean(data?.length);

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720, mt: 2 }}>
            <Table>
              <TableHead>
                {/* <TableRow>
                  <TableCell>
                    {translate("userFinancial.depositWallet.no")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.depositWallet.userName")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.depositWallet.paymentMethod")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.depositWallet.paymentAmount")}
                  </TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell>{translate("userFinancial.eWallet.no")}</TableCell>
                  <TableCell>
                    {translate("userFinancial.depositWallet.userName")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.eWallet.amountType")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.eWallet.amount")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.eWallet.transactionType")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.fundTransfer.note")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.eWallet.date")}
                  </TableCell>
                  <TableCell>
                    {translate("userFinancial.eWallet.paymentStatus")}
                  </TableCell>
                </TableRow>
              </TableHead>
              {isEmpty ? (
                <BodyRow>
                  <TableCell colSpan={7} align="center">
                    <EmptyTable title="No Data Available" />
                  </TableCell>
                </BodyRow>
              ) : (
                <TableBody>
                  {data.map(
                    (
                      {
                        id,
                        payment_type,
                        total_amount,
                        from_user,
                        type,
                        created_at,
                        payment_status,
                        note
                      },
                      i
                    ) => {
                      return (
                        // <TableRow key={id}>
                        //   <TableCell>{i + rowStart}</TableCell>
                        //   <TableCell>{from_user.username}</TableCell>
                        //   <TableCell>{payment_type}</TableCell>
                        //   <TableCell>
                        //     <Currency>{total_amount}</Currency>
                        //   </TableCell>
                        // </TableRow>
                        <TableRow>
                          <TableCell>{i + rowStart}</TableCell>
                          <TableCell>{from_user.username}</TableCell>
                          <TableCell>{payment_type}</TableCell>
                          <TableCell>
                            <Currency>{total_amount}</Currency>
                          </TableCell>
                          <TableCell>{type}</TableCell>
                          <TableCell>{note}</TableCell>
                          <TableCell>
                            {new Date(created_at).toLocaleDateString("en-GB")}
                          </TableCell>
                          <TableCell>{payment_status}</TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
      <PaginationButtons {...rest} />
    </>
  );
};

export default DataList;
